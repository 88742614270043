import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import * as actions from "../state/actions/keymans"
import { Location } from "@reach/router"

import { graphql } from "gatsby"

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import KeymanItem from "../components/common/keyman-item"
import Pagination from "../components/common/pagination"
import Banner from "../components/common/banner"
import Card from "../components/common/card"
import BreadcrumbList from "../components/common/breadcrumb-list"
import RankCard from "../components/common/rank-card"
import ServiceDescription from "../components/atoms/service-description"
import LinkToLpText from "../components/atoms/link-to-lp-text"
import LinkToUpdate from "../components/common/link-to-update"

import { addCommas, getQueryVariable } from "../utils/str"
import { DEPARTMENT_PER_PAGE } from "../constants/constants"

export const query = graphql`
  query DepartmentsQuery($slug: String) {
    departmentsJson(fields: { slug: { eq: $slug } }) {
      name
      value
    }
  }
`

const mapStateToProps = (state, ownProps) => {
  return {
    keymans: state.department.keymans.toIndexedSeq().toArray(),
    pageInfo_keyman: state.department.pageInfo_keyman,
    isLoadingKeymans: state.department.isLoadingKeymans,
    department: state.department.department,
    isLoadingDepartmentRank: state.department.isLoadingDepartmentRank,
    rankStats: state.department.rankStats,
    keymansTotal: state.global.keymansTotal,
    departmentCount: state.departments.departmentCount,
    corporationsTotal: state.global.corporationsTotal,
    updateKeymans: state.global.updateKeymans.toIndexedSeq().toArray(),
    isLoadingUpdateKeymans: state.global.isLoadingUpdateKeymans,
    keymanCountYstd: state.global.keymanCountYstd,
    isInitialized: state.department.isInitialized,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getKeymans: (data, overwrite) =>
      dispatch(actions.getKeymans(data, overwrite)),
    getKeymansYesterday: () => dispatch(actions.getKeymansYesterday()),
    getKeymansTotal: () => dispatch(actions.getKeymansTotal()),
    getDepartmentsById: id => dispatch(actions.getDepartmentsById(id)),
    getRankDepartmentsById: id => dispatch(actions.getRankDepartmentsById(id, null)),
    getRankJsicLDepartmentsById:(id, data) => dispatch(actions.getRankJsicLDepartmentsById(id, data)),
    countForIndustyCustomPages: data => dispatch(actions.countForIndustyCustomPages(data)),
    getRankCorporationDepartments: data => dispatch(actions.getRankCorporationDepartments(data)),
    getRankCorporationDepartmentsById:(id, data) => dispatch(actions.getRankCorporationDepartmentsById(id, data)),
  }
}

const styles = theme => ({
  subheading: {
    fontWeight: "bold",
    color: "#5A5B62",
    marginBottom: "18px",
  },
})

class Department extends React.Component {
  componentDidMount() {
    const { path } = this.props
    const jsic_l_val = path ? path.match("industries/(.*)/departments") : ''

    //業界と部門のかけあわせページの場合
    if (jsic_l_val) {
      this.props.countForIndustyCustomPages({jsic_l: [decodeURI(jsic_l_val[1])], department: [this.props.data.departmentsJson.value]})
      this.props.getRankJsicLDepartmentsById(this.props.data.departmentsJson.value, {jsic_l: decodeURI(jsic_l_val[1])})

    // かけあわせページ以外
    } else {
      this.props.getRankDepartmentsById(this.props.data.departmentsJson.value)
      this.props.getDepartmentsById(this.props.data.departmentsJson.value)
    }
    this.props.getKeymansTotal()
    this.props.getKeymansYesterday()
    this.props.getKeymans(this.getQuery(this.props), true)
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isLoadingKeymans && prevProps.isLoadingKeymans) {
      if (!!window) window.scrollTo(0, 0)
    }

    if (this.props.location.href !== prevProps.location.href) {
      this.props.getKeymans(this.getQuery(this.props))
    }
  }

  getQuery = (props, query = {}) => {
    const { path } = this.props
    const jsic_l_val = path ? path.match("industries/(.*)/departments") : ''
    return {
      paging: 1,
      per_page: DEPARTMENT_PER_PAGE,
      page: 1,
      department: [props.data.departmentsJson.value],
      jsic_l: jsic_l_val ? [decodeURI(jsic_l_val[1])] : '',
      ...getQueryVariable(props.location.search, ["page"]),
      ...query,
    }
  }

  render() {
    const {
      classes,
      data,
      keymanCountYstd,
      keymansTotal,
      corporationsTotal,
      department,
      pageInfo_keyman,
      isLoadingKeymans,
      rankStats,
      departmentCount,
      isInitialized,
      path,
    } = this.props
    const jsic_l_val = path ? path.match("industries/(.*)/departments") : ''
    let jsic_l = ''

    if (jsic_l_val) {
      jsic_l = decodeURI(jsic_l_val[1])
    }

    const keymans =
      (!isInitialized) ||
      (isLoadingKeymans && this.props.keymans && this.props.keymans.length === 0)
        ? Array.from(Array(DEPARTMENT_PER_PAGE))
        : this.props.keymans

    let customText = jsic_l_val ? `${jsic_l}の` : ''
    let executiveCount = ' - '
    if (jsic_l_val) {
      executiveCount = departmentCount ? departmentCount[this.props.data.departmentsJson.value] : " - "
    } else {
      executiveCount = department ? department[this.props.data.departmentsJson.value] : " - "
    }

    return (
      <Layout hasFooterPopup={true}>
        {path && path.indexOf('/departments') !== -1 && (
          <SEO
            title={customText + `${data.departmentsJson.name}部門の決裁者一覧`}
            description={customText + `${data.departmentsJson.name}部門の決裁者一覧です。大企業決裁者との商談獲得のためのレター送付ツール「キーマンレター」なら48万人以上の決裁者をリスト化してレターでコンタクトが取れます。`}
            canonical={`https://keyman-db.smart-letter.com${path}`}
          />
        )}
        <div className="container">
          <div className="list-container list-margin">
            <main className="left">
              <BreadcrumbList
                list={jsic_l_val ?
                  [ { path: "/", text: "トップ" },
                    { path: "/industries/" + jsic_l, text: jsic_l },
                    { path: "/industries/" + jsic_l + "/departments", text: jsic_l + "の部門一覧"}] :
                  [ { path: "/", text: "トップ" },
                    { path: "/departments", text: "部門一覧" }]
                }
                current={jsic_l_val ?
                  { path: path,
                    text: `${jsic_l}の${data.departmentsJson.name}部門の決裁者一覧` } :
                  { path: `/departments/${data.departmentsJson.value}`,
                    text: `${data.departmentsJson.name}部門の決裁者一覧` }
                }
              />
              <div>
                <h2 className="title">
                {customText}{data.departmentsJson.name}部門の決裁者一覧
                </h2>
                <ServiceDescription
                  children={<LinkToLpText/>}
                />
              </div>

              <RankCard
                id="department-rank"
                type="部門"
                name={customText + data.departmentsJson.name}
                data={rankStats[data.departmentsJson.value]}
              />

              {keymans.length > 0 && (
                <p className={classes.subheading}>
                  {executiveCount ? addCommas(executiveCount) : ' - '}
                  人の決裁者が見つかりました
                </p>
              )}

              {keymans.length === 0 ? (
                <div>決裁者がありません。</div>
              ) : (
                <div className="list">
                  {keymans.map((item, i) => (
                    <KeymanItem
                      item={item}
                      key={i}
                      showDepartment={false}
                      showIndustry={true}
                      showBorderBottom={i !== keymans.length - 1}
                    />
                  ))}
                </div>
              )}

              {keymans.length > 0 && pageInfo_keyman ? (
                <Pagination
                  currentPage={pageInfo_keyman.current_page}
                  lastPage={pageInfo_keyman.last_page}
                  perPage={DEPARTMENT_PER_PAGE}
                  path={path ? path.slice(1) : ''}
                  total={pageInfo_keyman.total}
                  onChange={(params, overwrite) =>
                    this.props.getKeymans(
                      this.getQuery(this.props, params),
                      overwrite
                    )
                  }
                />
              ) : (
                <div style={{ height: "213px" }}></div>
              )}
            </main>

            <div className="right">
              <div className="banner">
                <Banner short={true} />
              </div>

              <h2 className="title">昨日更新された決裁者の人数</h2>
              <LinkToUpdate
                keymanCountYstd={keymanCountYstd}
                updateKeymans={this.props.updateKeymans}
                isLoadingUpdateKeymans={this.props.isLoadingUpdateKeymans}
              />
              <Card
                keymanCountYstd={keymanCountYstd}
                keymansTotal={keymansTotal}
                corporationsTotal={corporationsTotal}
                style={{
                  marginBottom: "35px",
                }}
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(props => (
    <Location>
      {locationProps => <Department {...locationProps} {...props} />}
    </Location>
  ))
)
