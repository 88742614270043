import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { formatPercentage } from "../../utils/str"
import { DUMMY_RANK_STATS } from "../../constants/constants"

const styles = theme => ({
  root: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "relative",
    width: "100%",
    padding: "22px 24px",
    margin: "20px 0px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    boxShadow: "0px 0px 10px #254e781a",
  },

  title: {
    marginBottom: "15px",
    fontWeight: "bold",
    display: "inline",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },

  rankContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    paddingLeft: "20px",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },

  item: {
    fontSize: "16px",
    marginBottom: "4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },

  rankTitle: {
    marginRight: "20px",
  },

  rankNo: {
    fontSize: "28px",
    fontWeight: "bold",
    marginRight: "5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },
})

class RankCard extends React.Component {
  render() {
    const { classes, type, name, id, data, ...other } = this.props

    let stats = !!data ? data : DUMMY_RANK_STATS

    return (
      <div className={classes.root} id={id} {...other}>
        <h3 className={classes.title}>
          <span>{`${name}${type}`}</span>
          <span>のレター送付・商談率 ランキング</span>
        </h3>
        <div className={classes.rankContainer}>
          <div className={classes.item}>
            <div>
              <span className={classes.rankTitle}>レター送付率 ランキング</span>
              <span className={classes.rankNo}>{stats.letter_rank}</span>
              <span>/{stats.denominator}位</span>
            </div>
            <p>{`(${name}${type}はレター送付数全体の${formatPercentage(
              stats.letter_percentage
            )}の割合を占めています)`}</p>
          </div>

          <div className={classes.item}>
            <div>
              <span className={classes.rankTitle}>レター確認率 ランキング</span>
              <span className={classes.rankNo}>{stats.reaction_rank}</span>
              <span>/{stats.denominator}位</span>
            </div>
            <p>{`(${name}${type}はレターリアクション回数全体の${formatPercentage(
              stats.reaction_percentage
            )}の割合を占めています)`}</p>
          </div>

          <div className={classes.item}>
            <div>
              <span className={classes.rankTitle}>商談率 ランキング</span>
              <span className={classes.rankNo}>{stats.shoudan_rank}</span>
              <span>/{stats.denominator}位</span>
            </div>
            <p>{`(${name}${type}は商談達成回数全体の${formatPercentage(
              stats.shoudan_percentage
            )}の割合を占めています)`}</p>
          </div>
        </div>
      </div>
    )
  }
}

RankCard.propTypes = {
  classes: PropTypes.object.isRequired,
}

RankCard.defaultProps = {}

export default withStyles(styles)(RankCard)
