import React from "react"
import PropTypes from "prop-types"

const Human = props => {
  const { color, width, height, ...other } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34.775 34.775"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        id="Shape"
        d="M0,0H34.776V34.776H0Z"
        fill="none"
        fillRule="evenodd"
      />
      <path
        id="Mask"
        d="M13.8,14.592a5.8,5.8,0,1,1,5.8-5.8A5.8,5.8,0,0,1,13.8,14.592Z"
        transform="translate(3.592 1.347)"
        fill={color}
      />
      <path
        id="Mask-Copy"
        d="M3,23.431C3.563,16.516,9.175,13,16.017,13c6.938,0,12.637,3.323,13.062,10.433a1,1,0,0,1-1.088,1.159H4.054A1.6,1.6,0,0,1,3,23.431Z"
        transform="translate(1.347 5.837)"
        fill={color}
        opacity="0.5"
      />
    </svg>
  )
}

Human.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Human.defaultProps = {
  color: "#FFFFFF",
  width: 34.775,
  height: 34.775,
}

export default Human
